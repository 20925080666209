export default {
  1: {
    cover: require('@/assets/session-keynote-video-1.jpg'),
    title: '專訪 谷元宏｜momo 業績是同業 2 倍，秘訣在哪？',
    description: '零售消費戰場劇烈變化，電商最有感。當全台訂單大塞車時，momo 如何提前進化，不只出貨順暢，甚至躍升為全台電商第一品牌？馬上就來破解 momo 的數位變種基因！',
    facebookEmbedVideo: 'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FTechOrange%2Fvideos%2F281861126679000%2F&width=734&show_text=false&height=414&appId',
    applePodcast: 'https://podcasts.apple.com/tw/podcast/2021-connect-ep-1-%E5%B0%88%E8%A8%AA-%E8%B0%B7%E5%85%83%E5%AE%8F-momo-%E6%A5%AD%E7%B8%BE%E6%98%AF%E5%90%8C%E6%A5%AD-2-%E5%80%8D-%E7%A7%98%E8%A8%A3%E5%9C%A8%E5%93%AA/id1573886324?i=1000533956534',
    spotify: 'https://open.spotify.com/episode/33WXEMHrWS5PWlmgVVseGe?si=3acfc0137c92464a',
  },
  2: {
    cover: require('@/assets/session-keynote-video-2.jpg'),
    title: '專訪 簡立峰｜數位經濟當道，台灣如何開創全新 GDP？',
    description: '疫情一次引爆數位經濟、零接觸商業模式發展。教育、消費、工作、醫療服務的需求孔急，台灣是否具備創新體質來迎戰疫後浪潮？',
    facebookEmbedVideo: 'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FTechOrange%2Fvideos%2F579066883241772%2F&width=734&show_text=false&height=414&appId',
    applePodcast: 'https://podcasts.apple.com/tw/podcast/2021-connect-ep-2-%E5%B0%88%E8%A8%AA-%E7%B0%A1%E7%AB%8B%E5%B3%B0-%E6%95%B8%E4%BD%8D%E7%B6%93%E6%BF%9F%E7%95%B6%E9%81%93-%E5%8F%B0%E7%81%A3%E5%A6%82%E4%BD%95%E9%96%8B%E5%89%B5%E5%85%A8%E6%96%B0-gdp/id1573886324?i=1000533956533',
    spotify: 'https://open.spotify.com/episode/5qpUDNgJV6oElBOwHXdF23?si=11RU9M-0QmyrkFldxWyVRQ&dl_branch=1',
  },
  3: {
    cover: require('@/assets/session-keynote-video-3.jpg'),
    title: '專訪 林之晨｜數位人才變異速度，要比病毒更快！',
    description: '數位經濟在崛起，傳統產業的影響力正在下降中！人類生來就是高適應力物種，這個關鍵能力如何能讓新人才開創新時代？',
    facebookEmbedVideo: 'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FTechOrange%2Fvideos%2F319599136589417%2F&width=734&show_text=false&height=414&appId',
    applePodcast: 'https://podcasts.apple.com/tw/podcast/2021-connect-ep-3-%E5%B0%88%E8%A8%AA-%E6%9E%97%E4%B9%8B%E6%99%A8-%E6%95%B8%E4%BD%8D%E4%BA%BA%E6%89%8D%E8%AE%8A%E7%95%B0%E9%80%9F%E5%BA%A6-%E8%A6%81%E6%AF%94%E7%97%85%E6%AF%92%E6%9B%B4%E5%BF%AB/id1573886324?i=1000533956759',
    spotify: 'https://open.spotify.com/episode/2dEq4BJRWBz6mhmcF9sOMg?si=EwItenz_SxO6zmH0kL3_fg&dl_branch=1',
  },
  4: {
    cover: require('@/assets/session-keynote-video-4.jpg'),
    title: '專訪 王健全｜台灣 GDP 創新高，為何服務業仍然掙扎中？',
    description: '台灣 2021Q4 仍要拼 GDP，五倍券能成為經濟強心針？只靠半導體苦撐的台灣經濟穩健嗎？服務業重災區如何走出新商機？',
    facebookEmbedVideo: 'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FTechOrange%2Fvideos%2F1116191535580432%2F&width=734&show_text=false&appId=1764718317110428&height=414',
    applePodcast: 'https://podcasts.apple.com/tw/podcast/2021-connect-ep-5-%E5%B0%88%E8%A8%AA-%E7%8E%8B%E5%81%A5%E5%85%A8-%E5%8F%B0%E7%81%A3-gdp-%E5%89%B5%E6%96%B0%E9%AB%98-%E7%82%BA%E4%BD%95%E6%9C%8D%E5%8B%99%E6%A5%AD%E4%BB%8D%E7%84%B6%E6%8E%99%E6%89%8E%E4%B8%AD/id1573886324?i=1000537039344',
    spotify: 'https://open.spotify.com/episode/5Yj6aPp0U6pjZqJcbg6NSS?si=MH-wQQyIQViYtdAJTh6FMg&dl_branch=1',
  },
  5: {
    cover: require('@/assets/session-keynote-video-5.jpg'),
    title: '專訪 劉思泰｜5G 驅動行動運算大未來，如何創造台灣新經濟價值？',
    description: '未來 5G 世代 PC 要跟手機一樣，一打開螢幕就是連網狀態，而且電池要更節能又持久。這樣的改變，會為未來的生活與工作帶來哪些新改變？',
    facebookEmbedVideo: 'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FTechOrange%2Fvideos%2F300541248298350%2F&width=734&show_text=false&appId=1764718317110428&height=413',
    applePodcast: 'https://podcasts.apple.com/tw/podcast/2021-connect-ep-8-%E8%B6%A8%E5%8B%A2%E6%BC%94%E8%AC%9B-5g%E9%A9%85%E5%8B%95%E8%A1%8C%E5%8B%95%E9%81%8B%E7%AE%97%E5%A4%A7%E6%9C%AA%E4%BE%86-%E5%A6%82%E4%BD%95%E5%89%B5%E9%80%A0%E5%8F%B0%E7%81%A3%E6%96%B0%E7%B6%93%E6%BF%9F%E5%83%B9%E5%80%BC/id1573886324?i=1000539567390',
    spotify: 'https://open.spotify.com/episode/7crLJ6GaBO8GaEpypM0Cfz',
  },
  6: {
    cover: require('@/assets/session-keynote-video-6.jpg'),
    title: '專訪 陳良基｜國家數位轉型不能只做半套！小心台灣「加拉巴哥化」',
    description: 'COVID-19 襲捲全球，台灣疫情相較全世界，算是守備得當。但，這對我們來說算是好事一件嗎？前科技部長、現任台大電機工程學系講座教授陳良基在採訪中以「加拉巴哥群島」與「達爾文演化論」為台灣疫後新常態提出示警——台灣數位新國家之夢，即將夢醒還是成真？',
    facebookEmbedVideo: 'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FTechOrange%2Fvideos%2F674700376757708%2F&width=734&show_text=false&appId=1214287015283810&height=413',
    applePodcast: 'https://podcasts.apple.com/tw/podcast/2021-connect-ep-9-%E5%B0%88%E8%A8%AA-%E9%99%B3%E8%89%AF%E5%9F%BA-%E7%96%AB%E6%83%85%E8%B6%A8%E7%B7%A9-%E5%9C%8B%E5%AE%B6%E6%95%B8%E4%BD%8D%E8%BD%89%E5%9E%8B%E5%B0%B1%E5%81%9A%E5%8D%8A%E5%A5%97/id1573886324?i=1000539816623',
    spotify: 'https://open.spotify.com/episode/6j3Mb9CZtshp2RXgWbSusp?si=CPCc1Ow7R_CED7_NwLVNBQ',
  },
  7: {
    cover: require('@/assets/session-keynote-video-7.jpg'),
    title: '專訪 李維斌｜解密鴻海研究院 — 打造台灣護國群山最強王牌登場',
    description: '鴻海再以電動車重新定義製造業的進化速度!位居背後的「鴻海研究院」如何找到擲地有聲的關鍵研發領域，帶領集團持續創新技術與轉型？',
    facebookEmbedVideo: 'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FTechOrange%2Fvideos%2F571096640629946%2F&width=734&show_text=false&appId=1764718317110428&height=413',
    applePodcast: 'https://podcasts.apple.com/tw/podcast/2021-connect-ep-10-%E5%B0%88%E8%A8%AA-%E6%9D%8E%E7%B6%AD%E6%96%8C-%E8%A7%A3%E5%AF%86%E9%B4%BB%E6%B5%B7%E7%A0%94%E7%A9%B6%E9%99%A2-%E5%8F%B0%E7%81%A3%E8%AD%B7%E5%9C%8B%E7%BE%A4%E5%B1%B1%E8%83%8C%E5%BE%8C%E7%8E%8B%E7%89%8C%E7%99%BB%E5%A0%B4/id1573886324?i=1000540311474',
    spotify: 'https://open.spotify.com/episode/08ngK2oDJNPIJvWBEANbzn',
  },
  8: {
    cover: require('@/assets/session-keynote-video-8.jpg'),
    title: "HTC｜教育科技 What's Next？ VR 開啟校園、企業教育「元宇宙」",
    description: '疫情加速教育與科技的結合，當學校教育、企業內訓轉往線上，如何提供「沉浸式體驗」、「多人協作」環境成線上教學最頭痛問題！ HTC 將以「 VR 教育 X 元宇宙」帶來哪些創新體驗？',
    facebookEmbedVideo: 'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FTechOrange%2Fvideos%2F216794757229855%2F&width=734&show_text=false&appId=1764718317110428&height=413',
    applePodcast: 'https://podcasts.apple.com/tw/podcast/2021-connect-ep-15-%E5%B0%88%E8%A8%AA-htc-%E6%95%99%E8%82%B2%E7%A7%91%E6%8A%80-whats-next-vr-%E9%96%8B%E5%95%9F%E6%A0%A1%E5%9C%92-%E4%BC%81%E6%A5%AD%E6%95%99%E8%82%B2-%E5%85%83%E5%AE%87%E5%AE%99/id1573886324?i=1000543301884',
    spotify: 'https://open.spotify.com/episode/7qT4gWVomKtbhb0qT6u6RA?si=967ed3162acd4e54',
  },
}
