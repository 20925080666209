export default {
  1: {
    portrait: require('@/assets/speaker-portrait-1.jpg'),
    name: '林之晨',
    title: '台灣大哥大總經理',
  },
  2: {
    portrait: require('@/assets/speaker-portrait-2.jpg'),
    name: '谷元宏',
    title: 'momo 總經理',
  },
  3: {
    portrait: require('@/assets/speaker-portrait-3.jpg'),
    name: '簡立峰',
    title: 'Google 台灣前董事總經理',
  },
  4: {
    portrait: require('@/assets/speaker-portrait-4.jpg'),
    name: '王健全',
    title: '中華經濟研究院副院長',
  },
  5: {
    portrait: require('@/assets/speaker-portrait-5.jpg'),
    name: '丁彥宇',
    title: 'Lalamove 台灣負責人',
  },
  6: {
    portrait: require('@/assets/speaker-portrait-6.jpg'),
    name: '蘇柏州',
    title: '凱鈿行動科技創辦人',
  },
  7: {
    portrait: require('@/assets/speaker-portrait-7.jpg'),
    name: '黃昭瑛',
    title: 'KKday 營銷長',
  },
  8: {
    portrait: require('@/assets/speaker-portrait-8.jpg'),
    name: '林鼎鈞',
    title: 'PressPlay 共同創辦人暨知識內容執行長',
  },
  9: {
    portrait: require('@/assets/speaker-portrait-9.jpg'),
    name: '陳瑞弦',
    title: 'Foodland Ventures 扶田資本 CEO',
  },
  10: {
    portrait: require('@/assets/speaker-portrait-10.jpg'),
    name: '歐仕邁',
    title: '庫幣科技創辦人暨執行長',
  },
  11: {
    portrait: require('@/assets/speaker-portrait-11.jpg'),
    name: '劉佩真',
    title: '台經院產經資料庫總監',
  },
  12: {
    portrait: require('@/assets/speaker-portrait-12.jpg'),
    name: '陳良基',
    title: '國立台灣大學電機工程學系講座教授',
  },
  13: {
    portrait: require('@/assets/speaker-portrait-13.jpg'),
    name: '吳志毅',
    title: '工研院電光系統所所長',
  },
  14: {
    portrait: require('@/assets/speaker-portrait-14.jpg'),
    name: '葉怡君',
    title: '微軟智能工業雲解決方案亞太區總經理',
  },
  15: {
    portrait: require('@/assets/speaker-portrait-15.jpg'),
    name: '王傑智',
    title: '工業技術研究院機械與機電系統研究所數位長',
  },
  16: {
    portrait: require('@/assets/speaker-portrait-16.jpg'),
    name: '李維斌',
    title: '鴻海研究院執行長',
  },
  17: {
    portrait: require('@/assets/speaker-portrait-17.jpg'),
    name: '劉思泰',
    title: '美國高通公司副總裁暨台灣與東南亞區總裁',
  },
  18: {
    portrait: require('@/assets/speaker-portrait-18.jpg'),
    name: '李殷豪',
    title: 'Google Cloud 公共部門業務副總',
  },
  19: {
    portrait: require('@/assets/speaker-portrait-19.jpg'),
    name: '呂冠緯',
    title: '均一平台教育基金會董事長兼執行長',
  },
  20: {
    portrait: require('@/assets/speaker-portrait-20.jpg'),
    name: '陳怡蓉',
    title: '內湖高級中學英文科教師',
  },
  21: {
    portrait: require('@/assets/speaker-portrait-21.jpg'),
    name: '黃士維',
    title: '彰濱秀傳紀念醫院院長',
  },
  22: {
    portrait: require('@/assets/speaker-portrait-22.jpg'),
    name: '葉肇元',
    title: '雲象科技執行長',
  },
  23: {
    portrait: require('@/assets/speaker-portrait-23.jpg'),
    name: '許銀雄',
    title: '宏碁智醫總經理',
  },
  24: {
    portrait: require('@/assets/speaker-portrait-24.jpg'),
    name: '周成怡',
    title: 'HTC 產品經理',
  },
  25: {
    portrait: require('@/assets/speaker-portrait-25.jpg'),
    name: '蕭新晟',
    title: '應援科技執行長兼技術長',
  },
  26: {
    portrait: require('@/assets/speaker-portrait-26.jpg'),
    name: '詹婷怡',
    title: '數位經濟暨產業發展協會副理事長',
  },
}
