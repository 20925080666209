<template lang="pug">
aside#v-entrance
  label 獲取 CONNECT 最新消息
  button.signup(@click='$emit("show-form-popup")', type='button') 點我訂閱
</template>

<script>
export default {
}
</script>

<style lang="sass" scoped>
@import "~@/mixin.sass"

#v-entrance
  +flex(center, center)
  +position(fixed, null, 0, 0, 0)
  background-color: rgba(black, .75)
  height: 60px
  box-shadow: 0 -20px 25px -5px rgba(black, .1), 0 10px 10px -5px rgba(black, .04)
label
  +font(16px, white, null, .1em, 500)
  margin-right: .5em
.signup
  +ifont(16px, white, null, .1em, 500)
  +size(128px, 40px)
  background-color: #F16522
  border-radius: 9999px
</style>
