<template lang="pug">
#v-session2
  main(:style='{ backgroundImage: `url(${session.mainBg}), linear-gradient(#F2F2F2 0%, rgba(242, 242, 242, 0) 100%)` }'): .container
    router-link.logo(to='/'): img(src='@/assets/logo-techorange_connect2021.png', alt='CONNECT 2021 年度趨勢論壇')
    h1(:style='{ color: session.color }')
      img.kv(src='@/assets/session-main-kv.png', alt='CONNECT')
      .title
        span {{ session.title }}
        img.icon(:src='session.icon', :alt='session.title')
    h2 {{ session.subtitle }}
    p {{ session.introduction }}
  section.keynote: .container
    h3(:style='{ backgroundImage: `url(${session.keynoteUnderline})` }') 深度訪談 1 on 1
    v-keynotes(@show-video-popup='showVideoPopup', :keynote-ids='keynoteIds')
  .shortcut: .container: div
    a(@click.prevent='$scrollTo("#_article")', href='#article')
      span(:style='{ color: session.color }') 閱讀更多議題相關文章
      label(:style='{ backgroundColor: session.color }'): img(src='@/assets/icon-goto.png', alt='前往')
  section.panel: .container
    h3(:style='{ backgroundImage: `url(${session.panelUnderline})` }') 焦點對談
    v-panels(@show-video-popup='showVideoPopup', :panel-ids='panelIds')
  section.speaker: .container
    h4 講者陣容
    v-speakers(:speaker-ids='speakerIds')
  #_article: section.article: .container
    h4 更多相關文章
    v-articles(:session-id='sessionId', :article-ids='articleIds')
  v-footer
</template>

<script>
import { find, pick, pipe, propEq } from 'ramda'
import sessions from '@/data/sessions'
import VSpeakers from '@/components/Speakers.vue'
import VKeynotes from '@/components/Keynotes.vue'
import VPanels from '@/components/Panels.vue'
import VArticles from '@/components/Articles.vue'
import VFooter from '@/components/Footer.vue'

export default {
  components: {
    VSpeakers,
    VKeynotes,
    VPanels,
    VArticles,
    VFooter,
  },
  data() {
    return {
      sessionId: 2,
      keynoteIds: [4, 5, 7],
      panelIds: [2, 3, 5],
      speakerIds: [4, 5, 8, 9, 13, 11, 16, 14, 15, 17],
      articleIds: [19, 22, 40, 41, 42, 43, 44, 45, 23, 24, 25, 13, 15, 16, 17, 21],
    }
  },
  computed: {
    session() {
      return this.findAndTransformSession(this.sessionId, sessions)
    },
  },
  methods: {
    findAndTransformSession(sessionId, sessions) {
      const assocIcon = session => ({ ...session, icon: require(`@/assets/icon-session-${session.id}.png`) })
      const assocMainBg = session => ({ ...session, mainBg: require(`@/assets/session-main-bg-${session.id}.png`) })
      const assocKeynoteUnderline = session => ({ ...session, keynoteUnderline: require(`@/assets/session-keynote-underline-${session.id}.png`) })
      const assocPanelUnderline = session => ({ ...session, panelUnderline: require(`@/assets/session-panel-underline-${session.id}.png`) })
      return pipe(
        find(propEq('id', sessionId)),
        pick(['id', 'color', 'title', 'subtitle', 'introduction']),
        assocIcon,
        assocMainBg,
        assocKeynoteUnderline,
        assocPanelUnderline,
      )(sessions)
    },
    showVideoPopup(url) {
      this.$emit('show-video-popup', url)
    },
  },
}
</script>

<style lang="sass" scoped>
@import "~@/mixin.sass"

@keyframes bounce
  0%
    transform: translateY(0)
  50%
    transform: translateY(10px)
  100%
    transform: translateY(0)

main
  +background(null, (660px, 100%), no-repeat, (top center, center center))
  +padding-y(24px, 72px)
  .logo
    img
      width: 240px
  h1
    +margin-y(40px, 20px)
    .kv
      +margin-x(auto)
      display: block
      margin-bottom: 40px
      max-width: 480px
      width: 100%
    .title
      span
        +font(36px, null, null, .05em, bold)
        margin-right: .5em
      img
        width: 32px
  h2
    +font(20px, black, 1.2, .05em, bold)
    margin-bottom: 8px
  p
    +font(16px, black, 1.6, .05em)
    margin-bottom: 40px
.keynote
  +margin-x(auto)
  +padding-y(32px, 60px)
  max-width: 768px
  h3
    +background(null, 80px 3px, no-repeat, center bottom)
    +font(20px, black, null, .05em, bold, center)
    margin-bottom: 40px
    padding-bottom: 24px
.shortcut
  +margin-x(auto)
  margin-bottom: 40px
  max-width: 768px
  .container
    div
      +padding-y(24px)
      border-bottom: 1px solid black
      border-top: 1px solid black
      text-align: center
  a
    +iflex(null, center)
    &:hover
      text-decoration: none
    span
      +font(16px, null, null, .05em, 500)
      margin-right: .5em
    label
      +circle(20px)
      +iflex(center, center)
      img
        width: 12px
        transform: rotate(90deg)
.panel
  +margin-x(auto)
  +padding-y(36px, 72px)
  max-width: 640px
  h3
    +background(null, 80px 3px, no-repeat, center bottom)
    +font(20px, black, null, .05em, bold, center)
    margin-bottom: 40px
    padding-bottom: 24px
.speaker
  +margin-x(auto)
  +padding-y(36px, 72px)
  max-width: 768px
  h4
    +font(20px, #71717A, null, .05em, bold, center)
    margin-bottom: 36px
.article
  +margin-x(auto)
  +padding-y(36px, 72px)
  max-width: 768px
  h4
    +font(20px, #71717A, null, .05em, bold, center)
    margin-bottom: 36px

@media (min-width: 1280px)
  main
    +background(null, (768px, 100%), null, (calc(50% + 360px) center, center center))
    +padding-y(24px, 0)
    height: 400px
    .container
      position: relative
      height: 100%
    .logo
      +position(absolute, 16px, null, null, 60px)
      img
        width: 320px
    h1
      +margin-y(0)
      .kv
        +position(absolute, 16px, 60px)
        margin-bottom: 0
        width: 480px
      .title
        +position(absolute, 120px, null, null, 60px)
        span
          font-size: 48px
          text-align: left
        img
          width: 40px
    h2
      +position(absolute, 188px, null, null, 60px)
      font-size: 24px
      margin-bottom: 0
    p
      +position(absolute, 240px, null, null, 60px)
      margin-bottom: 0
      max-width: 540px
  .keynote
    +padding-y(48px, 96px)
    h3
      background-size: 320px 3px
      font-size: 24px
      margin-bottom: 80px
  .shortcut
    .container
      div
        +padding-y(32px)
    a
      span
        font-size: 20px
      label
        +size(32px)
        img
          width: 16px
  .panel
    +padding-y(48px, 96px)
    h3
      background-size: 320px 3px
      font-size: 24px
      margin-bottom: 80px
  .speaker
    +padding-y(48px, 96px)
    max-width: none
    h4
      font-size: 24px
      margin-bottom: 60px
  .article
    +padding-y(48px, 96px)
    max-width: 1024px
    h4
      font-size: 24px
      margin-bottom: 60px
</style>
