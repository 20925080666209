<template lang="pug">
form#v-form(@submit.prevent='submit')
  .dimmer(@click='$emit("hide")')
  template(v-if='status !== "submitted"')
    transition(name='popup', appear): .popup(key='form')
      .tip: p 立即訂閱，就能收到最新議題訊息
      .fields
        label.field.required
          span 姓名
          input(v-model='registrant.name', :disabled='status !== "pending"', name='name', type='text', required)
        label.field.required
          span 手機號碼
          input(v-model='registrant.mobile_phone', :disabled='status !== "pending"', name='mobile_phone', type='tel', placeholder='09XXXXXXXX', required)
        label.field.required
          span 公司名稱
          input(v-model='registrant.company_name', :disabled='status !== "pending"', name='company_name', type='text', required)
        label.field.required
          span 公司信箱
          input(v-model='registrant.email', :disabled='status !== "pending"', name='email', type='email', placeholder='xxx@example.com', required)
        label.field
          span 個人信箱
          input(v-model='registrant.email_personal', :disabled='status !== "pending"', name='email_personal', type='email', placeholder='xxx@example.com')
        label.field.required
          span 產業類別
          select(v-model='registrant.industry', :disabled='status !== "pending"', name='industry', required)
            option(value='', disabled)
            option(value='資訊軟體服務') 資訊軟體服務
            option(value='零售/電商') 零售/電商
            option(value='半導體') 半導體
            option(value='電腦及週邊設備') 電腦及週邊設備
            option(value='光電業') 光電業
            option(value='通信網路') 通信網路
            option(value='電信通訊') 電信通訊
            option(value='汽車工業') 汽車工業
            option(value='電子零組件') 電子零組件
            option(value='電機機械') 電機機械
            option(value='電器電纜') 電器電纜
            option(value='生技醫療') 生技醫療
            option(value='金融保險') 金融保險
            option(value='能源') 能源
            option(value='傳統製造') 傳統製造
            option(value='媒體行銷') 媒體行銷
            option(value='服務業') 服務業
            option(value='政府機關') 政府機關
            option(value='公協會/財團法人') 公協會/財團法人
            option(value='教育機構') 教育機構
            option(value='其他') 其他
        label.field
          span 職務名稱
          select(v-model='registrant.job_title', :disabled='status !== "pending"', name='job_title')
            option(value='', disabled)
            option(value='企業負責人') 企業負責人
            option(value='執行長') 執行長
            option(value='資訊長') 資訊長
            option(value='技術長') 技術長
            option(value='資安長') 資安長
            option(value='IT經理/IT總監/IT副總裁') IT經理/IT總監/IT副總裁
            option(value='MIS工程師') MIS工程師
            option(value='資料科學家') 資料科學家
            option(value='前端工程師') 前端工程師
            option(value='後端工程師') 後端工程師
            option(value='資安工程師') 資安工程師
            option(value='硬體工程師') 硬體工程師
            option(value='韌體工程師') 韌體工程師
            option(value='UI/UX 設計師') UI/UX 設計師
            option(value='產品經理/產品總監') 產品經理/產品總監
            option(value='商業開發經理') 商業開發經理
            option(value='業務銷售經理') 業務銷售經理
            option(value='行銷經理') 行銷經理
            option(value='顧問') 顧問
            option(value='研究員') 研究員
            option(value='教授') 教授
            option(value='學生') 學生
            option(value='其他') 其他
      .agreement
        label
          input(v-model='registrant.privacy_confirmation', :disabled='status !== "pending"', type='checkbox', name='privacy_confirmation', required)
          span
            | 我已詳閱並同意
            a(href='/CONNECT 2021年度趨勢論壇－個資蒐集聲明書（2021）.pdf', target='_blank') 「主辦單位蒐集個資聲明」
      .submission
        button(:disabled='status !== "pending"', type='submit') {{ button }}
  template(v-else)
    transition(name='popup', mode='out-in'): .popup(key='success')
      .success
        h4 報名已成功送出
        p
          | 將活動加入行事曆，
          br
          | 與你 online、on time 相見
        a(href='https://calendar.google.com/calendar/u/0/r?cid=https://calendar.google.com/calendar/ical/c_hbujjvf8tcmfvtuagq8ck0rs8s%40group.calendar.google.com/public/basic.ics', target='_blank')
          img(src='@/assets/icon-calender.png', alt='行事曆')
          span 加入 CONNECT 行事曆
</template>

<script>
import { __, dissoc, reject, isEmpty, mergeRight, pipe } from 'ramda'

export default {
  data() {
    return {
      status: 'pending', // pending, submitting, submitted
      registrant: {
        name: '',
        company_name: '',
        email_personal: '',
        mobile_phone: '',
        email: '',
        industry: '',
        job_title: '',
        privacy_confirmation: false,
      },
    }
  },
  computed: {
    button() {
      if(this.status === 'submitting') {
        return '提交中...'
      } else if(this.status === 'submitted') {
        return '已提交'
      } else {
        return '送出訂閱'
      }
    },
  },
  methods: {
    submit() {
      this.status = 'submitting'

      const mergeUtm = mergeRight(__, this.$store.state.utm)
      const rejectEmpty = reject(isEmpty)
      const registrant = pipe(mergeUtm, rejectEmpty)(this.registrant)

      this.$store.dispatch('postRegistrant', registrant).then(res => {
        setTimeout(() => { this.status = 'submitted' }, 1000)
      }).catch(err => {
        if(err.response.data.detail) {
          alert(`您的資料提交失敗\n${err.response.data.detail}`)
        } else {
          alert('系統忙碌中，請稍後再試')
        }
        this.status = 'pending'
      })
    },
  },
}
</script>

<style lang="sass" scoped>
@import "~@/mixin.sass"

.popup-enter-active
  transition: opacity .2s .2s, transform .2s .2s
.popup-leave-active
  transition: opacity .2s, transform .2s
.popup-enter, .popup-leave-to
  opacity: 0
  transform: translateY(-20px)

#v-form
  +flex(center, center)
  +position(fixed, 0, 0, 0, 0)
  z-index: 99
.dimmer
  +position(absolute, 0, 0, 0, 0)
  background-color: rgba(black, .5)
.popup
  background-color: white
  border-radius: 12px
  box-shadow: 0 25px 50px -12px rgba(black, .25)
  padding: 28px 20px
  position: relative
  width: calc(100% - 32px)
  max-width: 400px
.tip
  p
    +font(16px, black, null, .05em, 500, center)
    margin-bottom: 12px
.fields
  display: grid
  grid-gap: 16px
  margin-bottom: 24px
  .field
    position: relative
    span
      +font(14px, #71717A, null, .1em)
      +position(absolute, 50%, null, null, 0)
      pointer-events: none
      transform: translateY(-50%)
      z-index: 1
    input[type='email'],
    input[type='tel'],
    input[type='text']
      +size(100%, 32px)
      background-color: transparent
      border-bottom: 1px solid #B4B4B4
      color: black
      padding-left: 7em
      transition: border-color .2s, opacity .2s
      &::placeholder
        color: #BCBEC0
      &:focus
        border-color: #F16522
      &:disabled
        opacity: .75
    select
      +background(url(~@/assets/icon-dropdown.png), 10px, no-repeat, right .5em center)
      +size(100%, 32px)
      background-color: white
      border-bottom: 1px solid #B4B4B4
      color: black
      padding-left: 7em
      transition: opacity .2s
      &:focus
        border-color: #F16522
      &:disabled
        opacity: .75
    &.required
      span
        &::after
          color: #F16522
          content: '*'
.agreement
  margin-bottom: 16px
  label
    +flex(center, center)
    margin-bottom: 8px
    input[type='checkbox']
      margin-top: 4px
    span
      +font(12px, #8F8F8F, null, .05em)
      a
        color: #F16522
.submission
  text-align: center
  button
    +ifont(16px, white, null, .05em, 500)
    +size(160px, 40px)
    background-color: #F16522
    border-radius: 100px
    transition: opacity .2s
    &:disabled
      opacity: .75
.success
  text-align: center
  h4
    +font(20px, black, null, .05em, bold)
    margin-bottom: 16px
  p
    +font(16px, black, 1.5, .05em)
    margin-bottom: 24px
  a
    +iflex(center, center)
    +ifont(16px, white, null, .05em, 500)
    +size(264px, 44px)
    background-color: #F16522
    border-radius: 9999px
    &:hover
      text-decoration: none
    img
      margin-right: .5em
      width: 20px

@media (min-width: 1280px)
  .popup
    padding: 40px 56px
    max-width: 480px
</style>
