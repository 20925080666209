<template lang="pug">
.v-video
  .dimmer(@click='$emit("hide")')
  span 請稍候...
  iframe(:src='url', style='border:none;overflow:hidden', scrolling='no', frameborder='0', allowfullscreen='true', allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share', allowFullScreen='true')
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  }
}
</script>

<style lang="sass" scoped>
@import "~@/mixin.sass"

.v-video
  +flex(center, center)
  +position(fixed, 0, 0, 0, 0)
  z-index: 99
.dimmer
  +position(absolute, 0, 0, 0, 0)
  background-color: rgba(black, .75)
span
  +font(16px, white, null, .05em)
  +position(absolute, 50%, null, null, 50%)
  transform: translate(-50%, -50%)
iframe
  +size(100vw, calc(100vw / 734 * 414))
  max-height: 414px
  max-width: 734px
  position: relative
  width: 100vw

@media (min-width: 1280px)
  span
    font-size: 18px
</style>
