<template lang="pug">
footer#v-footer: .container
  address
    a.logo(href='https://buzzorange.com/techorange/', target='_blank')
      img(src='@/assets/logo-techorange.png', alt='TechOrange 科技報橘')
    ul
      li(v-for='socialLink of socialLinks'): a(:href='socialLink.link', target='_blank'): img(:src='socialLink.icon', :alt='socialLink.name')
  small
    | Copyright © {{year}} 流線傳媒有限公司 All rights reserved.
    ul
      li: a(href='https://buzzorange.com/techorange/', target='_blank') TechOrange科技報橘
      li: a(href='https://buzzorange.com/', target='_blank') CitiOrange公民報橘
      li: a(href='https://buzzorange.com/vidaorange/', target='_blank') VidaOrange生活報橘
</template>

<script>
import { map, pick } from 'ramda'
import socialLinks from '@/data/socialLinks'

export default {
  name: 'Footer',
  data() {
    return {
      socialLinks: this.transformSocialLinks(socialLinks),
      year: new Date().getFullYear(),
    }
  },
  methods: {
    transformSocialLinks(socialLinks) {
      return map(pick(['name', 'icon', 'link']))(socialLinks)
    },
  },
}
</script>

<style lang="sass" scoped>
@import "~@/mixin.sass"

#v-footer
  background-color: #F2F2F2
  height: 320px
  padding-top: 60px
address
  text-align: center
  .logo
    margin-bottom: 28px
    img
      width: 168px
  ul
    +margin-y(32px, 40px)
    display: grid
    grid-gap: 20px
    grid-template-columns: repeat(auto-fit, 40px)
    justify-content: center
    list-style-type: none
    li
      a
        +circle(40px)
        +iflex(center, center)
        background-color: #F16522
        img
          width: 24px
small
  +font(12px, #5B5B5B, 1.5, null, 300, center)
  +margin-x(auto)
  display: block
  transform-origin: 0 50%
  transform: scale(.9)
  width: calc(100% / 0.9)
  span
    +margin-x(.5em)
  ul
    list-style-type: none
    display: inline-flex
    li
      &:not(:last-child)::after
        +margin-x(.5em)
        content: '|'

@media (min-width: 1280px)
  address
    +flex(space-between, center)
    .logo
      margin-bottom: 0
    ul
      grid-auto-flow: column

  small
    text-align: left
    transform: none
    width: 100%
</style>
