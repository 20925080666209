<template lang="pug">
nav#v-menu(:class='{shown}'): .container
  button.show(@click='shown = true', type='button'): img(src='@/assets/icon-menu.png', alt='主選單')
  .dimmer(@click='shown = false')
  .menu
    button.close(@click='shown = false', type='button'): img(src='@/assets/icon-close.png', alt='關閉')
    a.logo(@click.prevent='goto("/")', href='/'): img(src='@/assets/logo-white-techorange_connect2021.png', alt='CONNECT 2021 年度趨勢論壇')
    ul
      li: a(@click.prevent='goto("/")', href='/') 首頁
      li(v-for='session of sessions')
        router-link(v-if='!session.commingSoon', @click.native.prevent='goto(session.path)', :to='session.path') {{ session.title }}
        span(v-else)
          | {{ session.title }}
          sub COMING SOON
      li: a(href='https://buzzorange.com/techorange/about-connect/', target='_blank') 關於 CONNECT
    address: ul
      li(v-for='socialLink of socialLinks'): a(:href='socialLink.link', target='_blank'): img(:src='socialLink.icon', :alt='socialLink.name')
</template>

<script>
import { map, pick } from 'ramda'
import sessions from '@/data/sessions'
import socialLinks from '@/data/socialLinks'

export default {
  data() {
    return {
      shown: false,
      sessions: this.transformSessions(sessions),
      socialLinks: this.transformSocialLinks(socialLinks),
    }
  },
  methods: {
    transformSessions(sessions) {
      return map(pick(['path', 'title', 'commingSoon']))(sessions)
    },
    transformSocialLinks(socialLinks) {
      return map(pick(['name', 'icon', 'link']))(socialLinks)
    },
    goto(path) {
      this.shown = false
      if(path !== this.$route.path) {
        setTimeout(() => {
          this.$router.push(path)
        }, 100)
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import '~@/mixin.sass'

#v-menu
  +position(fixed, 0, 0, 0, 0)
  pointer-events: none
  z-index: 99
  &.shown
    .dimmer
      opacity: 1
      pointer-events: auto
    .menu
      box-shadow: -25px 0 50px -12px rgba(black, .25)
      transform: translateX(0)
.show
  +circle(40px)
  +position(absolute, 16px, 16px)
  background-color: white
  box-shadow: 0 4px 6px -1px rgba(black, .1), 0 2px 4px -1px rgba(black, .06)
  pointer-events: auto
  img
    width: 16px
.dimmer
  +position(absolute, 0, 0, 0, 0)
  background-color: rgba(black, .5)
  opacity: 0
  transition: opacity .3s
.menu
  +flex(null, null, column)
  +position(absolute, 0, 0, 0)
  background-color: #252525
  padding: 48px 32px 60px
  pointer-events: auto
  transform: translateX(100%)
  transition: transform .4s
  width: 300px
  .close
    +position(absolute, 28px, 28px)
    img
      width: 16px
  .logo
    display: block
    margin-bottom: 32px
    img
      width: 200px
  > ul
    list-style-type: none
    li
      +font(16px, #EAEAEA, null, .05em, 500)
      &:not(:last-child)
        margin-bottom: 32px
      a
        &:hover
          text-decoration: none
      span
        color: #71717A
        sub
          +font(12px, #BCBEC0, 1.15)
          bottom: 0
          font-family: 'Oxanium'
          margin-left: 1em
          margin-top: 8px
  address
    margin-top: auto
    padding-top: 40px
    ul
      +flex(null, center)
      list-style-type: none
      li
        &:not(:last-child)
          margin-right: 16px
        a
          padding: 4px
          img
            width: 24px

@media (min-width: 1280px)
  .show
    +position(null, 48px, 72px)
    +size(60px)
    img
      width: 24px
  .menu
    padding: 72px 60px 108px
    width: 400px
    .close
      +position(null, 28px, 28px)
      padding: 8px
      img
        width: 20px
    .logo
      margin-bottom: 64px
      img
        width: 240px
    > ul
      li
        font-size: 20px
</style>
