<template lang="pug">
ul#v-keynotes
  li(v-for='keynote of keynotes')
    h4 {{ keynote.title }}
    p {{ keynote.description }}
    button.video(@click='$emit("show-video-popup", keynote.facebookEmbedVideo)', type='button'): img(:src='keynote.cover', :alt='keynote.title')
    .podcast
      a(v-if='keynote.applePodcast', :href='keynote.applePodcast', target='_blank'): img(src='@/assets/link-apple_podcasts.png', alt='收聽科技橘子 Spotify')
      a(v-if='keynote.spotify', :href='keynote.spotify', target='_blank'): img(src='@/assets/link-spotify.png', alt='收聽科技橘子 Apple Podcasts')
</template>

<script>
import { map, pick, pipe, props } from 'ramda'
import keynotes from '@/data/keynotes'

export default {
  props: {
    keynoteIds: {
      type: Array,
      required: true,
    },
  },
  computed: {
    keynotes() {
      return this.mapToKeynotes(this.keynoteIds, keynotes)
    },
  },
  methods: {
    mapToKeynotes(keynoteIds, keynotes) {
      return pipe(
        props(keynoteIds),
        map(pick(['cover', 'title', 'description', 'facebookEmbedVideo', 'applePodcast', 'spotify']))
      )(keynotes)
    },
  },
}
</script>

<style lang="sass" scoped>
@import '~@/mixin.sass'

#v-keynotes
  list-style-type: none
  li
    &:not(:last-child)
      padding-bottom: 52px
      border-bottom: 1px solid #BCBEC0
      margin-bottom: 36px
h4
  +font(20px, black, 1.2, .05em, bold)
  margin-bottom: 24px
p
  +font(16px, black, 1.6, .05em, null, justify)
  margin-bottom: 28px
.video
  +margin-x(auto)
  border-radius: 8px
  display: block
  margin-bottom: 12px
  max-width: 640px
  overflow: hidden
  position: relative
  &::after
    +background(url(~@/assets/icon-play.png))
    +position(absolute, 50%, null, null, 50%)
    +size(64px)
    content: ''
    display: block
    transform: translate(-50%, -50%)
  img
    width: 100%
.podcast
  +margin-x(auto)
  max-width: 640px
  text-align: right
  a
    &:not(:last-child)
      margin-right: 12px
    img
      width: 144px

@media (min-width: 1280px)
  #v-keynotes
    li
      &:not(:last-child)
        padding-bottom: 80px
        margin-bottom: 64px
  h4
    font-size: 30px
  p
    font-size: 18px
    margin-bottom: 40px
</style>
