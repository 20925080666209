<template lang="pug">
ul#v-speakers
  li(v-for='speaker of speakers')
    img(:src='speaker.portrait', :alt='`${speaker.title} ${speaker.name}`')
    div
      .name {{ speaker.name }}
      .title {{ speaker.title }}
</template>

<script>
import { map, pick, pipe, props } from 'ramda'
import speakers from '@/data/speakers'

export default {
  props: {
    speakerIds: {
      type: Array,
      required: true,
    },
  },
  computed: {
    speakers() {
      return this.mapToSpeakers(this.speakerIds, speakers)
    },
  },
  methods: {
    mapToSpeakers(speakerIds, speakers) {
      return pipe(
        props(speakerIds),
        map(pick(['portrait', 'name', 'title']))
      )(speakers)
    },
  },
}
</script>

<style lang="sass" scoped>
@import '~@/mixin.sass'

#v-speakers
  display: grid
  grid-gap: 24px
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr))
  list-style-type: none
  li
    +flex(null, center, column)
    img
      +circle(80px)
      margin-bottom: 12px
    .name
      +font(16px, #2A2A2A, null, .05em, 500, center)
      margin-bottom: 8px
    .title
      +font(14px, #71717A, 1.2, .05em, null, center)

@media (min-width: 1280px)
  #v-speakers
    grid-gap: 32px 64px
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr))
    li
      flex-direction: row
      img
        +size(100px)
        margin-bottom: 0
        margin-right: 20px
      .name
        +font(20px, null, null, null, null, left)
      .title
        +font(16px, null, null, null, null, left)
</style>
