<template lang="pug">
#v-home
  main: .container
    img.bg(src='@/assets/home-main-bg.png', alt='')
    img.logo(src='@/assets/logo-techorange.png', alt='TechOrange 科技報橘')
    nav(v-if='!$store.state.mobile'): ul
      li(v-for='session of sessions')
        router-link(v-if='!session.commingSoon', :to='session.path') {{ session.title }}
        span(v-else) {{ session.title }}
    h1
      img.kv(src='@/assets/home-main-kv.png', alt='CONNECT')
      img.title(src='@/assets/home-main-title.png', alt='2021 年度趨勢論壇')
    .scroll: img(src='@/assets/icon-scroll.png', alt='往下探索')
  section.intro: .container
    h2
      | Everything from Home
      br
      | New GDP 全新定義中
    hr
    p 在 2021，世界一夕之間快轉進化數十年。
    p
      | 疫後數位新經濟強勢崛起，而這股創新吞噬守舊的浪潮銳不可當，台灣未來會如何蛻變？《TechOrange》年度趨勢論壇
      |
      strong CONNECT
      |
      | 陪你一起探索未來的可能模樣。
    button.video(@click='$emit("show-video-popup", "https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FTechOrange%2Fvideos%2F946350085925589%2F&width=734&show_text=false&appId=526262931329331&height=414")', type='button')
      img(src='@/assets/home-intro-video.jpg', alt='Everything from Home，New GDP 全新定義中')
  section.session
    .interlude: .container
      strong 在最混亂的時代，鎖定最清晰的數位轉型目標
      p 下一個 Winner or Loser 會是誰？
      hr
    .list: .container: ol
      li(v-for='session of sessions')
        router-link(:to='session.path'): .banner(:style='{ backgroundImage: `url("${session.bannerBg}")` }')
          label {{ `0${session.id}` }}
          h3
            span {{ session.title.slice(0, 2) }}
            | {{ session.title.slice(2) }}
          img(:src='session.icon', :alt='session.title')
        .content
          router-link(:to='session.path'): h4 {{ session.subtitle }}
          router-link(:to='session.path'): p {{ session.description }}
          .goto(v-if='!session.commingSoon')
            router-link(:to='session.path', :style='{backgroundColor: session.color}'): img(src='@/assets/icon-goto.png', alt='前往')
          .remind(v-else)
            time {{ session.date }}
            span Coming Soon
  section.speaker: .container
    h4 講者陣容
    v-speakers(:speaker-ids='speakerIds')
  section.sponsor: .container
    .group
      h4 指導單位
      div
        img(src='@/assets/logo-ndc.png', alt='國家發展委員會')
        img(src='@/assets/logo-startup_island.png', alt='Startup Island TAIWAN')
    .group
      h4 合作夥伴
      div
        img(src='@/assets/logo-momo.png', alt='momo')
        img(src='@/assets/logo-twm.png', alt='台灣大哥大')
        img(src='@/assets/logo-kkday.png', alt='KKDay')
        img(src='@/assets/logo-kdan.png', alt='Kdan Mobile')
        img(src='@/assets/logo-coolbitx.png', alt='酷幣科技')
        img(src='@/assets/logo-pressplay.png', alt='PressPlay')
        img(src='@/assets/logo-lalamove.png', alt='Lalamove')
        img(src='@/assets/logo-foodlandventures.png', alt='扶田資本')
        img(src='@/assets/logo-htc_vive.png', alt='HTC VIVE')
        img(src='@/assets/logo-microsoft.png', alt='Microsoft')
        img(src='@/assets/logo-nhsh.png', alt='內湖高中')
        img(src='@/assets/logo-junyiacademy.png', alt='均一教育平台')
        img(src='@/assets/logo-qualcomm.png', alt='美國高通公司')
        img(src='@/assets/logo-aetherai.png', alt='雲象科技')
        img(src='@/assets/logo-scmh.png', alt='彰濱秀傳醫院')
        img(src='@/assets/logo-acer_medical.png', alt='宏碁智醫')
        img(src='@/assets/logo-foxconn.png', alt='鴻海科技集團')
        img(src='@/assets/logo-oen.png', alt='應援科技')
        img(src='@/assets/logo-dta.png', alt='數位經濟暨產業發展協會')
  v-footer
</template>

<script>
import { map, pick, pipe } from 'ramda'
import sessions from '@/data/sessions'
import VSpeakers from '@/components/Speakers.vue'
import VFooter from '@/components/Footer.vue'

export default {
  components: {
    VSpeakers,
    VFooter,
  },
  data() {
    return {
      sessions: this.transformSessions(sessions),
      speakerIds: [1, 2, 3, 4, 7, 6, 5, 8, 9, 10, 11, 12, 13, 16, 14, 15, 17, 18, 19, 20, 24, 21, 22, 23, 25, 26],
    }
  },
  methods: {
    transformSessions(sessions) {
      const assocBannerBg = session => ({ ...session, bannerBg: require(`@/assets/home-session-bg-${session.id}.png`) })
      const assocIcon = session => ({ ...session, icon: require(`@/assets/icon-white-session-${session.id}.png`) })
      return map(pipe(
        pick(['id', 'path', 'color', 'title', 'subtitle', 'description', 'date', 'commingSoon']),
        assocBannerBg,
        assocIcon
      ))(sessions)
    },
  },
}
</script>

<style lang="sass" scoped>
@import "~@/mixin.sass"

@keyframes bounce
  0%
    transform: translateY(0)
  50%
    transform: translateY(10px)
  100%
    transform: translateY(0)

main
  +padding-y(24px, 72px)
  background: linear-gradient(#F2F2F2 0%, rgba(#F2F2F2, 0) 100%)
  overflow: hidden
  .container
    +flex(center, center, column)
    position: relative
  .bg
    +position(absolute, 50%, null, null, 50%)
    max-width: calc(520px * 1.68)
    transform: translate(-50%, -50%)
    width: calc(168% - 32px)
  .logo
    margin-bottom: 40px
    width: 120px
  h1
    +flex(null, center, column)
    position: relative
    margin-bottom: 40px
    .kv
      margin-bottom: 28px
      max-width: 520px
      width: 100%
    .title
      width: 240px
  .scroll
    text-align: center
    img
      animation: bounce 1s infinite
      width: 20px
.intro
  +margin-x(auto)
  +padding-y(36px, 72px)
  max-width: 768px
  h2
    +font(20px, black, 1.2, .05em, 500, center)
  hr
    +background(url(~@/assets/icon-divider.png))
    +margin-y(20px, 48px)
    +size(95px, 10px)
  p
    +font(16px, black, 1.6, .05em, null, justify)
    &:first-of-type
      +font(null, #F16522, null, null, 500, center)
      text-align: center
      margin-bottom: 24px
    &:last-of-type
      margin-bottom: 36px
  .video
    +margin-x(auto)
    border-radius: 8px
    display: block
    max-width: 640px
    overflow: hidden
    position: relative
    &::after
      +background(url(~@/assets/icon-play.png))
      +position(absolute, 50%, null, null, 50%)
      +size(64px)
      content: ''
      display: block
      transform: translate(-50%, -50%)
    img
      width: 100%
.session
  padding-top: 32px
  .interlude
    position: relative
    strong
      +font(20px, black, 1.6, .05em, bold, center)
      display: block
      margin-bottom: 8px
    p
      +font(16px, black, null, .05em, null, center)
      margin-bottom: 140px
    hr
      +position(absolute, null, null, -220px, 50%)
      +size(1px, 160px)
      background-color: black
  .list
    +padding-y(120px)
    background-color: #F2F2F2
    ol
      +margin-x(auto)
      list-style-type: none
      max-width: 640px
      li
        background-color: white
        &:not(:last-child)
          margin-bottom: 48px
        .banner
          +background(null, 100%, no-repeat, top center)
          height: 160px
          position: relative
          label
            +font(48px, white, null, 3px, 300)
            +position(absolute, 20px, null, null, 20px)
            display: block
            font-family: 'Oxanium'
            margin-bottom: 12px
          h3
            +font(24px, white, 1.2, .05em, 500)
            +position(absolute, null, null, 20px, 20px)
            span
              display: block
          img
            width: 32px
            +position(absolute, null, 20px, 20px)
        .content
          padding: 20px
          h4
            +font(20px, black, 1.6, .05em, bold)
            margin-bottom: 16px
          p
            +font(16px, black, 1.6, .05em, null, justify)
            margin-bottom: 40px
          .goto
            text-align: right
            a
              +circle(52px)
              +iflex(center, center)
              &:hover
                text-decoration: none
              img
                width: 20px
          .remind
            +font(16px, #71717A, null, .05em)
            text-align: right
.speaker
  +margin-x(auto)
  +padding-y(60px, 40px)
  max-width: 768px
  h4
    +font(20px, #71717A, null, .05em, bold, center)
    margin-bottom: 28px
.sponsor
  +margin-x(auto)
  +padding-y(60px, 100px)
  max-width: 768px
  h4
    +font(20px, #71717A, null, .05em, bold, center)
    margin-bottom: 28px
  .group
    &:not(:last-of-type)
      margin-bottom: 20px
    &:nth-of-type(1)
      div
        img
          height: 32px
    &:nth-of-type(2)
      div
        img
          &:nth-child(1)
            height: 24px
          &:nth-child(5)
            height: 20px
          &:nth-child(6)
            height: 24px
          &:nth-child(7)
            height: 32px
          &:nth-child(8)
            height: 24px
          &:nth-child(9)
            height: 20px
          &:nth-child(13)
            height: 24px
          &:nth-child(14)
            height: 40px
          &:nth-child(16)
            height: 24px
          &:nth-child(18)
            height: 40px
          &:nth-child(19)
            height: 24px
    div
      +flex(center, center, null, wrap)
      img
        height: 28px
        margin-bottom: 24px
        &:not(:last-of-type)
          margin-right: 40px

@media (min-width: 1280px)
  main
    .bg
      max-width: 1200px
      transform: translate(-50%, -50%) translateY(32px)
    .logo
      +position(absolute, 16px, null, null, 60px)
      margin-bottom: 0
      width: 160px
    nav
      +position(absolute, 24px, 0)
      ul
        list-style-type: none
        +flex(null, center)
        li
          &:not(:last-child)
            margin-right: 40px
          a
            +font(16px, black, null, .05em, 500)
            border-bottom: 2px solid transparent
            transition: border-color .2s
            &:hover
              text-decoration: none
              border-bottom: 2px solid #F16522
          span
            +font(16px, #71717A, null, .05em, 500)
            border-bottom: 2px solid transparent
    h1
      +margin-y(72px, 0)
      flex-direction: row
      transform: none
      .kv
        max-width: 720px
        transform: translateX(80px)
      .title
        width: 320px
        transform: translate(0, 120px)
    .scroll
      img
        width: 24px
  .intro
    +padding-y(48px, 96px)
    h2
      font-size: 36px
    hr
      +size(142px, 15px)
      +margin-y(32px, 56px)
    p
      font-size: 20px
      max-width: 768px
      &:first-of-type
        margin-bottom: 32px
      &:last-of-type
        margin-bottom: 48px
    .video
      &::after
        +size(80px)
  .session
    padding-top: 48px
    .interlude
      strong
        font-size: 36px
      p
        font-size: 24px
    .list
      ol
        max-width: none
        transform: none
        width: auto
        li
          display: flex
          height: 320px
          .banner
            +size(360px, 100%)
            flex: none
            label
              +position(null, 32px, null, null, 32px)
              font-size: 64px
            h3
              +position(null, null, null, 64px, 32px)
              font-size: 40px
            img
              width: 56px
              +position(null, null, 32px, 64px)
          .content
            padding: 32px
            position: relative
            h4
              font-size: 24px
              margin-bottom: 20px
            p
              font-size: 18px
              margin-bottom: auto
            .goto
              +position(absolute, null, 36px, 36px)
            .remind
              +position(absolute, null, 36px, 36px)
  .speaker
    +padding-y(100px, 36px)
    max-width: none
    h4
      margin-bottom: 72px
      text-align: left
  .sponsor
    +padding-y(100px, 160px)
    max-width: none
    h4
      margin-bottom: 48px
      text-align: left
    .group
      &:not(:last-of-type)
        margin-bottom: 60px
      &:nth-of-type(1)
        div
          img
            height: 48px
      &:nth-of-type(2)
        div
          img
            &:nth-child(1)
              height: 40px
            &:nth-child(5)
              height: 32px
            &:nth-child(6)
              height: 36px
            &:nth-child(7)
              height: 48px
            &:nth-child(8)
              height: 40px
            &:nth-child(9)
              height: 28px
            &:nth-child(13)
              height: 32px
            &:nth-child(14)
              height: 56px
            &:nth-child(16)
              height: 40px
            &:nth-child(18)
              height: 60px
            &:nth-child(19)
              height: 36px
      div
        justify-content: flex-start
        img
          height: 44px
          margin-bottom: 40px
          &:not(:last-of-type)
            margin-right: 56px
</style>
