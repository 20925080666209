<template lang="pug">
ul#v-articles
  li(v-for='article of articles', :style='{ borderColor: session.color }'): article
    h5
      span(v-if='article.marked') ★
      | {{ article.title }}
    label {{ article.author }}
    a(:href='article.link', :style='{backgroundColor: session.color}', target='_blank'): img(src='@/assets/icon-goto.png', alt='前往')
</template>

<script>
import { find, map, pick, pipe, propEq, props } from 'ramda'
import sessions from '@/data/sessions'
import articles from '@/data/articles'

export default {
  props: {
    sessionId: {
      type: Number,
      required: true,
    },
    articleIds: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      session: this.findAndTransformSession(this.sessionId, sessions),
    }
  },
  computed: {
    articles() {
      return this.mapToArticles(this.articleIds, articles)
    },
  },
  methods: {
    findAndTransformSession(sessionId, sessions) {
      return pipe(
        find(propEq('id', sessionId)),
        pick(['id', 'color']),
      )(sessions)
    },
    mapToArticles(articleIds, articles) {
      return pipe(
        props(articleIds),
        map(pick(['title', 'author', 'link', 'marked']))
      )(articles)
    },
  },
}
</script>

<style lang="sass" scoped>
@import '~@/mixin.sass'

#v-articles
  list-style-type: none
  li
    border-top: 1px solid
article
  +padding-y(16px)
  position: relative
  h5
    +font(18px, black, 1.5, .05em, 500)
    margin-bottom: 48px
  label
    +font(14px, #71717A, null, .05em)
    &::before
      content: '文/'
  a
    +position(absolute, null, 0, 16px)
    +circle(40px)
    +iflex(center, center)
    &:hover
      text-decoration: none
    img
      width: 20px

@media (min-width: 1280px)
  article
    h5
      font-size: 20px
      margin-bottom: 12px
    label
      font-size: 16px
</style>
