<template lang="pug">
#v-app
  transition(name='cut', mode='out-in'): router-view(@show-video-popup='showVideoPopup', @show-form-popup='formPopupShown = true')
  transition(name='drop'): v-menu(v-if='menuShown')
  transition(name='fade'): v-video(v-if='videoPopup.shown', @hide='videoPopup.shown = false', :url='videoPopup.url')
  v-entrance(@show-form-popup='formPopupShown = true')
  transition(name='fade'): keep-alive: v-form(v-if='formPopupShown', @show-success-popup='successPopupShown = true', @hide='formPopupShown = false')
</template>

<script>
import VMenu from '@/components/Menu.vue'
import VVideo from '@/components/Video.vue'
import VEntrance from '@/components/Entrance.vue'
import VForm from '@/components/Form.vue'

export default {
  name: 'App',
  components: {
    VVideo,
    VEntrance,
    VForm,
    VMenu,
  },
  data() {
    return {
      menuShown: true,
      formPopupShown: false,
      videoPopup: {
        url: '',
        shown: false,
      },
    }
  },
  methods: {
    checkIsMobile() {
      const mobile = $(window).width() < 1280
      if(this.$store.state.mobile !== mobile) {
        if(mobile) {
          this.$store.commit('setMobile', true)
        } else {
          this.$store.commit('setMobile', false)
        }
      }
    },
    checkMenuShown() {
      if(!this.$store.state.mobile && this.$route.path === '/' && $(window).scrollTop() < $(window).innerHeight() - 72) {
        this.menuShown = false
      } else {
        this.menuShown = true
      }
    },
    showVideoPopup(url) {
      this.videoPopup = {
        url,
        shown: true,
      }
    },
  },
  watch: {
    $route(to, from) {
      $(window).scroll()
    },
  },
  created() {
    document.cookie=`XSRF-TOKEN=${Math.random().toString(36).substr(2)}`

    $(window).resize(() => {
      this.checkIsMobile()
      $(window).scroll()
    }).resize()
    $(window).scroll(this.checkMenuShown).scroll()

    this.$store.commit('saveUtm', this.$route.query)
  },
}
</script>

<style lang="sass" scoped>
@import "~@/mixin.sass"

.cut-enter-active, .cut-leave-active
  transition: opacity .3s, transform .3s
.cut-enter, .cut-leave-to
  opacity: 0
  transform: translateY(-20px)
.drop-enter-active, .drop-leave-active
  transition: opacity .2s, transform .3s
.drop-enter, .drop-leave-to
  opacity: 0
  transform: translateY(-60px)
.fade-enter-active, .fade-leave-active
  transition: opacity .2s
.fade-enter, .fade-leave-to
  opacity: 0

#v-app
  min-height: 100%
  min-width: 360px
</style>
