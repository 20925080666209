export default {
  1: {
    cover: require('@/assets/session-panel-video-1.jpg'),
    title: '台式新經濟解碼，Next Big 新創點點名！',
    facebookEmbedVideo: 'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FTechOrange%2Fvideos%2F210547091057162%2F&width=734&show_text=false&height=414&appId',
    applePodcast: 'https://podcasts.apple.com/tw/podcast/2021-connect-ep-4-%E7%84%A6%E9%BB%9E%E5%B0%8D%E8%AB%87-%E5%8F%B0%E5%BC%8F%E6%96%B0%E7%B6%93%E6%BF%9F%E8%A7%A3%E7%A2%BC-next-big-%E6%96%B0%E5%89%B5%E9%BB%9E%E9%BB%9E%E5%90%8D/id1573886324?i=1000535050828',
    spotify: 'https://open.spotify.com/episode/5HKmddFLho1HW2v77DllQi?si=5XfvkHT-RjSUGsrpZWzRmQ&dl_branch=1',
  },
  2: {
    cover: require('@/assets/session-panel-video-2.jpg'),
    title: '怪物新秀出列！新服務如何締造全新 GDP？',
    facebookEmbedVideo: 'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FTechOrange%2Fvideos%2F542272030173048%2F&width=734&show_text=false&appId=1764718317110428&height=414',
    applePodcast: 'https://podcasts.apple.com/tw/podcast/2021-connect-ep-6-%E7%84%A6%E9%BB%9E%E5%B0%8D%E8%AB%87-%E6%80%AA%E7%89%A9%E6%96%B0%E7%A7%80%E5%87%BA%E5%88%97-%E6%96%B0%E6%9C%8D%E5%8B%99%E5%A6%82%E4%BD%95%E7%B7%A0%E9%80%A0%E5%85%A8%E6%96%B0-gdp/id1573886324?i=1000537779625',
    spotify: 'https://open.spotify.com/episode/7n4oy6hsxPbabJtinMhCHA',
  },
  3: {
    cover: require('@/assets/session-panel-video-3.jpg'),
    title: '全球晶片危機，如何帶動台灣半導體升級？',
    facebookEmbedVideo: 'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FTechOrange%2Fvideos%2F312119257385489%2F&width=734&show_text=false&appId=1764718317110428&height=414',
    applePodcast: 'https://podcasts.apple.com/tw/podcast/2021-connect-ep-7-%E7%84%A6%E9%BB%9E%E5%B0%8D%E8%AB%87-%E5%85%A8%E7%90%83%E6%99%B6%E7%89%87%E5%8D%B1%E6%A9%9F-%E5%A6%82%E4%BD%95%E5%B8%B6%E5%8B%95%E5%8F%B0%E7%81%A3%E5%8D%8A%E5%B0%8E%E9%AB%94%E5%8D%87%E7%B4%9A/id1573886324?i=1000538628023',
    spotify: 'https://open.spotify.com/episode/4rmLNVVBoo7pmodQUGi1DY?si=ipYdRjR1RV2OW1e0e4uOiA',
  },
  4: {
    cover: require('@/assets/session-panel-video-4.jpg'),
    title: '疫情催動數位革命，台灣教育數位轉型不能打 R 檔！',
    facebookEmbedVideo: 'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FTechOrange%2Fvideos%2F559369518696196%2F&width=734&show_text=false&appId=1214287015283810&height=413',
    applePodcast: 'https://podcasts.apple.com/tw/podcast/2021-connect-ep-11-%E7%84%A6%E9%BB%9E%E5%B0%8D%E8%AB%87-%E7%96%AB%E6%83%85%E5%82%AC%E5%8B%95%E6%95%B8%E4%BD%8D%E9%9D%A9%E5%91%BD-%E5%8F%B0%E7%81%A3%E6%95%99%E8%82%B2%E6%95%B8%E4%BD%8D%E8%BD%89%E5%9E%8B%E4%B8%8D%E8%83%BD%E6%89%93-r-%E6%AA%94/id1573886324?i=1000540556907',
    spotify: 'https://open.spotify.com/episode/5sxhTb5tMf0erAPMfXn6wM?si=a2dd216a60e248e9',
  },
  5: {
    cover: require('@/assets/session-panel-video-5.jpg'),
    title: '電動車時代，為台灣軟體開發帶來轉型新機會？',
    facebookEmbedVideo: 'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FTechOrange%2Fvideos%2F253725590067147%2F&width=734&show_text=false&appId=1214287015283810&height=413',
    applePodcast: 'https://podcasts.apple.com/us/podcast/2021-connect-ep-12-%E7%84%A6%E9%BB%9E%E5%B0%8D%E8%AB%87-%E9%9B%BB%E5%8B%95%E8%BB%8A%E6%99%82%E4%BB%A3-%E7%82%BA%E5%8F%B0%E7%81%A3%E8%BB%9F%E9%AB%94%E9%96%8B%E7%99%BC%E5%AF%A6%E5%8A%9B%E5%B8%B6%E4%BE%86%E7%BF%BB%E8%BA%AB%E6%A9%9F%E6%9C%83/id1573886324?i=1000540821793',
    spotify: 'https://open.spotify.com/episode/6pYrbKqp1wDWlGQErE0057?si=eG_y4YH0Rs6YPcM3cEG5ZA',
  },
  6: {
    cover: require('@/assets/session-panel-video-6.jpg'),
    title: '台灣公民權遲未數位轉型，元宇宙會是解答嗎？',
    facebookEmbedVideo: 'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FTechOrange%2Fvideos%2F1029418604270948%2F&width=734&show_text=false&appId=1214287015283810&height=413',
    applePodcast: 'https://podcasts.apple.com/tw/podcast/2021-connect-ep-13-%E7%84%A6%E9%BB%9E%E5%B0%8D%E8%AB%87-%E7%B6%B2%E8%B7%AF%E5%8F%AF%E5%AF%A6%E7%8F%BE%E7%9B%B4%E6%8E%A5%E6%B0%91%E4%B8%BB-%E7%82%BA%E4%BD%95%E5%8F%B0%E7%81%A3%E5%85%AC%E6%B0%91%E6%AC%8A%E9%81%B2%E9%81%B2%E6%9C%AA%E6%95%B8%E4%BD%8D%E8%BD%89%E5%9E%8B/id1573886324?i=1000542789432',
    spotify: 'https://open.spotify.com/episode/56Zmr5JvgtBeZZ91pXkYqH?si=47124d4c4c5b4aaa',
  },
  7: {
    cover: require('@/assets/session-panel-video-7.jpg'),
    title: '台灣發展智慧醫療，還停留在「談趨勢」階段嗎？',
    facebookEmbedVideo: 'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FTechOrange%2Fvideos%2F322834705998807%2F&width=734&show_text=false&appId=1764718317110428&height=413',
    applePodcast: 'https://podcasts.apple.com/tw/podcast/2021-connect-ep-14-%E7%84%A6%E9%BB%9E%E5%B0%8D%E8%AB%87-%E7%96%AB%E6%83%85%E4%B8%8B-%E5%8F%B0%E7%81%A3%E7%B9%B3%E5%87%BA%E6%80%8E%E6%A8%A3%E7%9A%84-%E9%86%AB%E7%99%82%E6%95%B8%E4%BD%8D%E8%BD%89%E5%9E%8B-%E6%88%90%E7%B8%BE%E5%96%AE/id1573886324?i=1000543090113',
    spotify: 'https://open.spotify.com/episode/0ngUJjp9RGwRRcvf99A1PE',
  },
}
