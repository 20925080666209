export default [
  {
    id: 1,
    path: '/new-economy',
    color: '#3B5685',
    title: '創造新經濟',
    subtitle: '疫情催熟 Everything from Home 破壞式創新潮',
    description: '數位轉型時代需要通才新物種來引領潮流！當網路經濟轉向以「人」為服務核心發展，哪些人才、企業夠格搭上這台創新特快車？',
    introduction: '簡立峰、momo 總經理谷元宏與台灣大哥大總經理林之晨聯手勾勒後疫情世界樣貌，台式新經濟強勢崛起中！',
    date: '9/1（三）',
    commingSoon: false,
  },
  {
    id: 2,
    path: '/new-gdp',
    color: '#FF3F00',
    title: '定義全新 GDP',
    subtitle: '哪些新星企業崛起、誰又殞落下莊？',
    description: '台灣只靠半導體撐經濟榮景，掩蓋服務業愁雲慘霧？創造 GDP 需搶出口與拚內需，哪些 MVP 商模值得讚賞，哪些又不合時宜？',
    introduction: '台灣「護國神山」半導體未來走勢如何演變？電動車、新零售產業出線時機到了？匯集鴻海研究院、工研院、台經院等產學專家觀點，剖析 GDP 成長新動能！',
    date: '9/30（四）',
    commingSoon: false,
  },
  {
    id: 3,
    path: '/digital-country',
    color: '#25ACBE',
    title: '建構數位新國家',
    subtitle: '建造數位國家金三角：教育、醫療、網路民主',
    description: '台灣的未來願景，教育、醫療、數位民主金三角缺一不可！疫情高峰後，這些進展會成過眼雲煙，或更堅不可摧？',
    introduction: '一場疫情，讓我們看見台灣與理想中「數位新國家」的距離！從數位國家的關鍵金三角：教育、醫療、網路民主，探究台灣能否在各領域華麗數位轉型？',
    date: '10/27（三）',
    commingSoon: false,
  },
]
