export default {
  1: {
    title: '台灣被各國獨角獸包圍！簡立峰示警：企業將迎下波人才出走危機',
    author: 'HSIN',
    link: 'https://buzzorange.com/techorange/2021/08/25/unicorn-taiwan-2021/',
    marked: true,
  },
  2: {
    title: '【91APP、iKala 入列】新創國家代表隊出爐！國發會挑了哪 9 家企業當台灣新創代言人？',
    author: '中央社',
    link: 'https://buzzorange.com/techorange/2021/08/06/taiwan-startup-unicorn/',
    marked: true,
  },
  3: {
    title: '疫情下數位轉型需求大爆發！iKala 程世嘉：盼以東南亞經驗協助台灣企業',
    author: '藍立晴',
    link: 'https://buzzorange.com/techorange/2021/06/03/ikala-10-years/',
    marked: false,
  },
  4: {
    title: '導入 Appier 精準行銷，cama café 用 AI 為顧客量身打造天氣專屬咖啡！',
    author: '新聞快訊',
    link: 'https://buzzorange.com/techorange/2021/08/24/cama-cafe-appier-martech/',
    marked: false,
  },
  5: {
    title: '疫情過一年，餐飲業復甦了嗎？iCHEF：台灣餐飲業邁入「後外送時代」',
    author: '郭家宏',
    link: 'https://buzzorange.com/techorange/2021/04/16/taiwan-restaurant-post-pandemic/',
    marked: false,
  },
  6: {
    title: '【疫情加速數位化浪潮】KKday 獲 7500 萬美元 C 輪投資！預訂系統 Rezio 6 個月大衝刺，服務 15 萬旅客',
    author: '新聞快訊',
    link: 'https://buzzorange.com/techorange/2020/09/30/kkday-c/',
    marked: false,
  },
  7: {
    title: '當其他物流業被搞瘋，新竹物流的大膽轉型記：切入自駕車領域',
    author: '藍立晴',
    link: 'https://buzzorange.com/techorange/2021/08/11/hct-logistics-self-driving/',
    marked: false,
  },
  8: {
    title: '台灣新創如何創造疫後新商機？簡立峰：要比以前更積極參與虛擬世界',
    author: '陳宜伶',
    link: 'https://buzzorange.com/techorange/2021/09/02/taiwan-startup-big-picture/',
    marked: false,
  },
  9: {
    title: 'momo 訂單不延遲心法是什麼？谷元宏獨家解析疫情下電商必勝策略',
    author: '陳宜伶',
    link: 'https://buzzorange.com/techorange/2021/09/01/momo-ecommerce-winner/',
    marked: true,
  },
  10: {
    title: '掌握疫後時代的求職必勝絕招，林之晨：成為數位通才是關鍵！',
    author: '陳宜伶',
    link: 'https://buzzorange.com/techorange/2021/09/09/post-pandemic-job-hunt/',
    marked: false,
  },
  11: {
    title: 'After Covid-19: Former Google Taiwan Director Foresees Super-Unicorn Startups',
    author: 'Misha Lu',
    link: 'https://techtaiwan.com/20210906/google-taiwan-superunicorns/',
    marked: true,
  },
  12: {
    title: '台式新經濟解碼，統整 3 種必勝的國際新創經營心法！',
    author: '陳宜伶',
    link: 'https://buzzorange.com/techorange/2021/09/16/taiwan-stratup-guidebook/',
    marked: false,
  },
  13: {
    title: '第三代半導體是台灣的下一座護國神山？鴻海：得先掌握兩個關鍵',
    author: '鉅亨網',
    link: 'https://buzzorange.com/techorange/2021/09/08/third-generation-semi-taiwan/',
    marked: false,
  },
  14: {
    title: '台灣與荷蘭合作發展化合物半導體，相當於製造一台「概念車」',
    author: '郭家宏',
    link: 'https://buzzorange.com/techorange/2021/09/24/taiwan-netherlands-semi-cooperation/',
    marked: false,
  },
  15: {
    title: '【網購收包裹真的零接觸？】新竹物流、黑貓、Lalamove 有哪些科技防疫措施？',
    author: '何泰霖',
    link: 'https://buzzorange.com/techorange/2021/06/03/logistic-covid-measures/',
    marked: false,
  },
  16: {
    title: '鴻海斥資 25.2 億收購旺宏晶圓廠！解析鴻海的碳化矽車用半導體布局',
    author: '中央社',
    link: 'https://buzzorange.com/techorange/2021/08/06/honhai-acquire-mxic-factory/',
    marked: false,
  },
  17: {
    title: '電動車真的比燃油車環保嗎？我們從「生命週期」的角度來分析',
    author: '綠學院',
    link: 'https://buzzorange.com/techorange/2021/05/14/is-electric-vehicle-environmental-friendly/',
    marked: false,
  },
  18: {
    title: '瞄準新創 IC 設計公司，創投基金「益創一」要強化台灣第三代半導體創新',
    author: '郭家宏',
    link: 'https://buzzorange.com/techorange/2021/08/25/top-taiwan-semi-vc/',
    marked: false,
  },
  19: {
    title: '從英特爾到 NVIDIA，現在換「特斯拉」接下摩爾定律的領導權',
    author: '郭家宏',
    link: 'https://buzzorange.com/techorange/2021/08/27/tesla-lead-moore-law/',
    marked: true,
  },
  20: {
    title: '【五倍券資安之亂】郵局網頁大出包！資安專家：政府、企業要加強 DevSecOps 觀念',
    author: '藍立晴',
    link: 'https://buzzorange.com/techorange/2021/09/23/stimulus-vouchers-taiwan-pay-threat/',
    marked: false,
  },
  21: {
    title: '新鮮人平均要投 120 封履歷！有這類「數位技能」就有機會脫穎而出',
    author: '徐宇儂',
    link: 'https://buzzorange.com/techorange/2021/08/31/resume-upgrading-digital-marketing-skill/',
    marked: false,
  },
  22: {
    title: '半導體業之外，台灣哪些產業有望成下一個 GDP 強力成長引擎？',
    author: '何泰霖',
    link: 'https://buzzorange.com/techorange/2021/10/06/taiwan-2021-gdp-export/',
    marked: true,
  },
  23: {
    title: '內需服務業被疫情打到趴地，五倍券政策是有效強心針嗎？',
    author: '郭家宏',
    link: 'https://buzzorange.com/techorange/2021/10/06/stimulus-voucher-boost-economy/',
    marked: false,
  },
  24: {
    title: '創造台灣年輕人高薪機會，產業除了破除 Cost Down 心態還需要什麼要件？',
    author: '郭家宏',
    link: 'https://buzzorange.com/techorange/2021/10/06/where-investment-fund-come-from/',
    marked: false,
  },
  25: {
    title: '【台灣服務業轉生術】Lalamove、PressPlay、Foodland Ventures 如何解鎖台灣新服務玩法？',
    author: '徐宇儂',
    link: 'https://buzzorange.com/techorange/2021/10/07/lalamove-pressplay-foodland-ventures-new-dgp/',
    marked: false,
  },
  26: {
    title: '高通打造 5G 科技教育願景，學生頭戴 VR 裝置學英文會更有效嗎？',
    author: '陳宜伶',
    link: 'https://buzzorange.com/techorange/2021/09/30/qualcomm-taiwan-5g-education/',
    marked: false,
  },
  27: {
    title: '布局「教育科技」領域，微軟收購線上學習平台 TakeLessons',
    author: '郭家宏',
    link: 'https://buzzorange.com/techorange/2021/09/11/microsoft-acquire-takelessons/',
    marked: false,
  },
  28: {
    title: '馬斯克的育兒經：教育就該像電玩一樣有趣！我的孩子都在 YouTube 和 Reddit 學習',
    author: '賴佩萱',
    link: 'https://buzzorange.com/techorange/2021/02/03/elon-musk-talks-about-education/',
    marked: false,
  },
  29: {
    title: '傳蘋果將用 iPhone 實現遠距醫療，可透過打字速度追蹤憂鬱症！',
    author: '鉅亨網',
    link: 'https://buzzorange.com/techorange/2021/09/22/iphone-depression-tracking-apple/',
    marked: false,
  },
  30: {
    title: '蘋果、Google 智慧醫療夢相繼夢碎，科技巨頭進入醫療行業門檻在哪？',
    author: '陳宜伶',
    link: 'https://buzzorange.com/techorange/2021/08/23/google-health-apple-dismantling/',
    marked: false,
  },
  31: {
    title: '【輝達智慧醫療夢】NVIDIA 攜手 AI 新創薛丁格，掀起製藥產業新革命！',
    author: 'NVIDIA',
    link: 'https://buzzorange.com/techorange/2021/08/12/nvidia-partner-ai-schrodinger/',
    marked: false,
  },
  32: {
    title: '解密以色列創業發展戰略:舉國之力養出好的創投,才有源源不絕的獨角獸',
    author: 'TechTaiwan',
    link: 'https://buzzorange.com/techorange/2021/11/16/isarel-startup/',
    marked: true,
  },
  33: {
    title: '【當兵怎可不除草?】以色列把軍隊當高科技創業中心,台灣你各位自覺點啊!',
    author: 'TechTaiwan',
    link: 'https://buzzorange.com/techorange/2021/12/02/isarel-army-startup/',
    marked: true,
  },
  34: {
    title: 'Google 前 CEO Eric Schmidt 退而不休,他在以色列進行科技「超進化」的超級任務!',
    author: 'TechTaiwan',
    link: 'https://buzzorange.com/techorange/2021/12/07/eric-schmidt-innovation-endeavors/',
    marked: true,
  },
  35: {
    title: 'Post-Pandemic: After Gogoro, These Could Be the Next Taiwanese Startups on NASDAQ',
    author: 'Misha Lu',
    link: 'https://techtaiwan.com/20210917/kkday-coolbitx-kidan-nasdaq/',
    marked: true,
  },
  36: {
    title: 'impossible Foods SVP: We Can Flatline Greenhouse Gas Emissions by Transitioning to Plant-Based Food Ecosystem – APAC F&B Innovation Summit 2021',
    author: 'TechTaiwan',
    link: 'https://techtaiwan.com/20211129/food-svp-2021/',
    marked: true,
  },
  37: {
    title: 'Israel Key to Former Google Chairman’s VC for “Super Evolution”',
    author: 'Misha Lu',
    link: 'https://techtaiwan.com/20211126/innovation-endeavors/',
    marked: true,
  },
  38: {
    title: 'Israel’s Military Offers a Roadmap for Taiwan to Foster Startups',
    author: 'Misha Lu',
    link: 'https://techtaiwan.com/20211122/israel-unit8200/',
    marked: true,
  },
  39: {
    title: 'Israel’s Innovation Policies an Inspiration for Taiwan',
    author: 'Misha Lu',
    link: 'https://techtaiwan.com/20211109/israel-startup-yozma/',
    marked: true,
  },
  40: {
    title: '針對第三代半導體未來商機,台積電有怎樣的布局?',
    author: '郭家宏',
    link: 'https://buzzorange.com/techorange/2021/09/09/tsmc-deployment-in-third-gen-semi/',
    marked: true,
  },
  41: {
    title: '軟體定義的智慧車,劃開台灣下一個黃金十年產業新風口',
    author: '鄒昀倢',
    link: 'https://buzzorange.com/techorange/2021/11/05/software-defined-car/',
    marked: true,
  },
  42: {
    title: 'Facing Chinese Rivalry, Taiwan Evaluates Its Own Compound Semiconductor Industry',
    author: 'Misha Lu',
    link: 'https://techtaiwan.com/20211112/connect21-semiconductor/',
    marked: true,
  },
  43: {
    title: 'Not Just TSMC: This Small Industrial Cluster in Taiwan Also Crucial to Global Chip Supply Chain',
    author: 'Misha Lu',
    link: 'https://techtaiwan.com/20211124/taiwan-pcb-ecosystem/',
    marked: true,
  },
  44: {
    title: 'Software-Defined EVs Still Face Challenges',
    author: 'TechTaiwan',
    link: 'https://techtaiwan.com/20211117/software-defined-evs/',
    marked: true,
  },
  45: {
    title: 'Unveiling the Powerhouse That Drives Foxconn’s Transformation',
    author: 'Misha Lu',
    link: 'https://techtaiwan.com/20211103/foxconn-research-institute/',
    marked: true,
  },
}
