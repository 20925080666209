export default [
  {
    name: 'TechOrange 科技報橘粉絲專頁',
    icon: require('@/assets/icon-facebook.png'),
    link: 'https://www.facebook.com/TechOrange',
  },
  {
    name: 'TechOrange 科技報橘 LINE 官方帳號',
    icon: require('@/assets/icon-line.png'),
    link: 'https://page.line.me/vvx9094k?openQrModal=true',
  },
  {
    name: 'TechOrange 科技報橘 LinkedIn',
    icon: require('@/assets/icon-linkedin.png'),
    link: 'https://www.linkedin.com/showcase/techorange',
  },
  {
    name: '科技橘子 Spotify',
    icon: require('@/assets/icon-spotify.png'),
    link: 'https://open.spotify.com/show/7COOIkbytqa9XDWaqpo8NS?si=STKSyZIrTZOGFbTpfjTvXw&dl_branch=1',
  },
  {
    name: '科技橘子 Apple Podcasts',
    icon: require('@/assets/icon-apple_podcasts.png'),
    link: 'https://podcasts.apple.com/tw/podcast/%E7%A7%91%E6%8A%80%E6%A9%98%E5%AD%90/id1573886324',
  },
]
