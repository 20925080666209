import Vue from 'vue'
import Router from 'vue-router'

import sessions from '@/data/sessions'
import VHome from '@/views/Home.vue'
import VSession1 from '@/views/Session1.vue'
import VSession2 from '@/views/Session2.vue'
import VSession3 from '@/views/Session3.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, saved) {
    return new Promise(resolve => {
      const position = saved ? saved : { x: 0, y: 0 }
      setTimeout(() => resolve(position), 300)
    })
  },
  routes: [
    {
      path: '/',
      component: VHome,
    },
    {
      path: sessions[0].path,
      component: VSession1,
    },
    {
      path: sessions[1].path,
      component: VSession2,
    },
    {
      path: sessions[2].path,
      component: VSession3,
    },
    {
      path: '*',
      redirect: '/',
    },
  ]
})
